import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PriceService, PriceType } from '../services/price.service';
import { ServiceState } from '../models/servicestate.model';


@Component({
    selector: 'prices-page',
    templateUrl: './prices.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush
}) export class PricesPage implements OnInit, OnDestroy {

    private _subscriptions: Subscription[] = [];

    public constructor(
        private readonly _change_detector: ChangeDetectorRef,
        private readonly _price_service: PriceService) { }

    public pricelist: PriceType[] = []
    public pricelist_progress: string | null = null;

    public on_changed(event: { from: Date, to: Date }) { console.log(event); }

    public async ngOnInit(): Promise<void> {

        this._subscriptions.push(this._price_service.prices_obserbable.subscribe((event) => {
            if (event.state === ServiceState.Loading) {
                this.pricelist = this.pricelist.concat(event.data as PriceType[]);
                this.pricelist_progress = event.progress + '%';
                this._change_detector.detectChanges();
            }
            if (event.state === ServiceState.Success) {
                //this.pricelist_progress = '0';
                this._change_detector.detectChanges();
            }
        }));

        this._price_service.load_prices_async();

    }

    public async ngOnDestroy(): Promise<void> {
        for (let i = 0; i < this._subscriptions.length; ++i)
            this._subscriptions[i].unsubscribe();
    }

}
