<div class="row">
    <div class="col-0"></div>
    <div class="col-12">
        <div class="card rounded-0">
            <div class="p-1">
                <img src="./assets/start-pic-1.jpg" class=" card-img-top border border-secondary rounded-0">
            </div>
        </div>
    </div>
    <div class="col-0"></div>
</div>