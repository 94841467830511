<div class="jumbotron jumbotron-fluid
    text-center bg-light
    rounded-0
    pb-0 mt-2 mb-4
    shadow-sm
    header-bg">
    <h1 class="header-text">Villa Wittstock</h1>
    <b class="p-2 header-text">
        Wir begrüßen Sie in unserem gemütlichen Hotel.<br>
        Gerne zeigen wir Ihnen ein Paar Eindrücke und freuen uns<br>schon jetzt auf Ihren Besuch.
    </b>

    <nav class="nav nav-pills flex-column flex-sm-row mt-4 mb-0 header-nav">
        <span class="flex-sm-fill text-sm-center nav-link text-secondary rounded-0 header-link" (click)="menu_item_clicked('start')">START</span>
        <span class="flex-sm-fill text-sm-center nav-link text-secondary rounded-0 header-link" (click)="menu_item_clicked('about')">IMPRESSUM</span>
        <span class="flex-sm-fill text-sm-center nav-link text-secondary rounded-0 header-link" href="#">DIE UNTERKUNFT</span>
        <span class="flex-sm-fill text-sm-center nav-link text-secondary rounded-0 header-link" (click)="menu_item_clicked('prices')">DIE PREISE</span>
        <span class="flex-sm-fill text-sm-center nav-link text-secondary rounded-0 header-link" (click)="menu_item_clicked('booking')"href="#">JETZT BUCHEN</span>
    </nav>
</div>