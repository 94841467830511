import { Component } from "@angular/core";
import { Router } from '@angular/router';

@Component({
    selector: 'application-header',
    templateUrl: './header.component.html'
}) export class ApplicationHeaderComponent {

    public constructor(private readonly _router: Router) { }

    public menu_item_clicked(item: "start" | "about" | "rooms" | "prices" | "booking") {
        switch (item) {
            case "start":
                this._router.navigate(['index']);
                break;

            case "about":
                this._router.navigate(['about']);
                break;

            case "prices":
                this._router.navigate(['prices']);
                break;

            case "booking":
                this._router.navigate(['booking']);
                break;
        }
    }

}