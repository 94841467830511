import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";
import { ServiceState } from '../models/servicestate.model';
import { ErrorModel } from '../models/error.model';
import { Threading } from '../utils/threading.util';

export declare type PriceType = { designation: string, without: string, with: string };

@Injectable({ providedIn: 'root' })
export class PriceService {

    private _prices_subject: BehaviorSubject<{ data: PriceType[] | ErrorModel | null, state: ServiceState, progress: number | null }> =
        new BehaviorSubject<{ data: PriceType[] | ErrorModel | null, state: ServiceState, progress: number | null }>
            ({ data: [], state: ServiceState.Unknown, progress: null });
    public readonly prices_obserbable = this._prices_subject.asObservable();



    public constructor() { }
    public async load_prices_async(): Promise<void> {
        //TODO: durch http request ersetzen
        this._prices_subject.next({ data: [{ designation: 'Einzelzimmer', without: '34,50 €', with: '40,00 €' }], state: ServiceState.Loading, progress: 100 / 6 * 1 });
        this._prices_subject.next({ data: [{ designation: 'Doppelzimmer zur Einzelnutzung', without: '44,50 €', with: '50,00 €' }], state: ServiceState.Loading, progress: 100 / 6 * 1 });
        this._prices_subject.next({ data: [{ designation: 'Doppelzimmer', without: '49,00 €', with: '60,00 €' }], state: ServiceState.Loading, progress: 100 / 6 * 2 });
        this._prices_subject.next({ data: [{ designation: 'Dreibettzimmer', without: '60,00 €', with: '75,00 €' }], state: ServiceState.Loading, progress: 100 / 6 * 3 });
        this._prices_subject.next({ data: [{ designation: 'Wohnung für 2 Personen', without: '59,00 €', with: '70,00 €' }], state: ServiceState.Loading, progress: 100 / 6 * 4 });
        this._prices_subject.next({ data: [{ designation: 'Wohnung für 3 Personen', without: '60,00 € - 65,00 €', with: '75,00 € - 80,00 €' }], state: ServiceState.Loading, progress: 100 / 6 * 5 });
        this._prices_subject.next({ data: [{ designation: 'Wohnung für 4 Personen', without: '70,00 € - 75,00 €', with: '85,00 € - 90,00 €' }], state: ServiceState.Loading, progress: 100 / 6 * 6 });
        this._prices_subject.next({ data: [], state: ServiceState.Success, progress: 0 });
    }

}