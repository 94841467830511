import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { IndexCanActivate } from './guards/index.guards';
import { AboutCanActivate } from './guards/about.guards';
import { PricesCanActivate } from './guards/prices.guards';

import { IndexPage } from './pages/index.page';
import { AboutPage } from './pages/about.page';
import { PricesPage } from './pages/prices.page';

import { ApplicationComponent } from './components/application.component';
import { ApplicationHeaderComponent } from './components/header.component';
import { ApplicationFooterComponent } from './components/footer.component';


const routes: Routes = [
	{ path: 'index', component: IndexPage, canActivate: [IndexCanActivate] },
	{ path: 'about', component: AboutPage, canActivate: [AboutCanActivate] },
	{ path: 'prices', component: PricesPage, canActivate: [PricesCanActivate] },
	//{ path: 'booking', loadChildren: () => import('./booking/booking.module').then(m => m.TSBookingModule) },
	{ path: '', redirectTo: 'index', pathMatch: 'full' }
];

const angular_modules: Array<any> = [
	BrowserModule,
	HttpClientModule,
	RouterModule.forRoot(routes)
];

const application_modules: Array<any> = [];

const application_pages: Array<any> = [IndexPage, AboutPage, PricesPage];

const application_components: Array<any> = [
	ApplicationComponent,
	ApplicationHeaderComponent,
	ApplicationFooterComponent];

@NgModule({
	declarations: [application_pages, application_components],
	imports: [angular_modules, application_modules],
	bootstrap: [ApplicationComponent]
}) export class ApplicationModule { }
