<div class="row">
    <div class="col-12 pb-4">
        <h4>Preisliste</h4>
    </div>
</div>

<div class="row">
    <div class="col-7">
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th class="border-bottom-0"></th>
                    <th class="border-bottom-0">ohne Frühstück</th>
                    <th class="border-bottom-0">mit Frühstück</th>
                </tr>
            </thead>

            <tbody>
                <tr class="progress-row">
                    <td class="p-0 m-0 border-top-0" colspan="3">
                        <div class="progress rounded-0 p-0 m-0" style="height: 1px;">
                            <div class="progress-bar" [ngStyle]="{'width': pricelist_progress}">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let price of pricelist">
                    <td>{{price.designation}}</td>
                    <td>{{price.without}}</td>
                    <td>{{price.with}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="col-5">
        <div class="card rounded-0">
            <div class="p-1">
                <img src="./assets/prices-pic-1.jpg" class="card-img-top border border-secondary rounded-0">
            </div>
        </div>
    </div>
</div>