<b>Anschrift</b><br>
HOTEL VILLA WITTSTOCK<br>
Blumenthaler Landstraße 7<br>
39288 Burg (bei Magdeburg)<br><br>

<b>Telefon</b><br>
0049 (0) 3921 988987 (Tel)<br>
0049 (0) 3921 4826414 (Fax)<br>
0040 (0) 0172 2869447 (Mobil)<br><br>

<b>Inhaber</b><br>
Mirko Wittstock<br><br>

<b>Unternehmensform</b><br>
Einzelunternehmen im Hotelwesen<br><br>

<b>Steuer</b><br>
StNr.: 103/287/00269<br>
USt-ID.Nr.: DE162710950<br>
